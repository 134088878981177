<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">学院管理员管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addAdministrator()">创建管理员</el-button>
            </div>
        </div>
        <el-table :data="adminTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                  :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="名称" align="left" class-name="student-name">
                <template slot-scope="scope">
                    <span class="student-name-title">{{scope.row.name}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="username" label="账号" align="center"></el-table-column>
            <el-table-column prop="college_name" label="学院" align="center"></el-table-column>
            <el-table-column prop="head_portrait" label="头像" align="center">
                <template width="32" slot-scope="scope" class="avatar">
                    <img :src="scope.row.head_portrait?scope.row.head_portrait:require('../../assets/img/image/user_img.png')"/>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="260">
                <template slot-scope="scope">
                    <el-button size="small" @click="resetPassword(scope.row)">重置密码</el-button>
                    <el-button size="small" @click="editAdmin(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteAdmin(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange">
        </el-pagination>
        <!--S 添加/编辑弹窗-->
        <el-dialog :title="adminTitleType" :visible.sync="dialogAddAdmin" center width="30%" @close="resetForm()"
                   @opened="openAddAdmin" :close-on-click-modal="false">
            <el-form :model="addAdminForm" ref="addAdminForm" :rules="rules" label-position="left" class="student-info">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addAdminForm.name" ref="adminInput" autocomplete="off" placeholder="请输入名称" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
                    <el-input v-model="addAdminForm.username" v-if="this.adminTitleType==='创建学院管理员'" autocomplete="off" placeholder="请输入账号" style="width: 80%;"></el-input>
                    <el-input v-model="addAdminForm.username" v-if="this.adminTitleType==='编辑学院管理员'" disabled autocomplete="off" placeholder="请输入账号" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item v-if="this.adminTitleType==='创建学院管理员'" label="密码" :label-width="formLabelWidth" prop="password">
                    <el-input v-model="addAdminForm.password" type="password" autocomplete="off" placeholder="请输入密码" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="学院" :label-width="formLabelWidth" prop="college_id">
                    <el-select v-model="addAdminForm.college_name" placeholder="请选择学院" @change="classSelect($event)" style="width: 80%;">
                        <el-option
                                v-for="item in selectCollege"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="image">
                    <template>
                        <input class="up-img" @change="changeFile" type="file" id="id_avatar" name="file" title/>
                        <el-button size="small" type="primary">{{addAdminForm.image?'重新上传':'点击上传'}}</el-button>
                        <img :src="addAdminForm.image" v-show="addAdminForm.image" class="up-img-show" alt/>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" type="primary" @click="addToForm('addAdminForm')">确 定</el-button>
                <el-button size="medium" @click="dialogAddAdmin = false">取 消</el-button>
            </div>
        </el-dialog>
        <!--E 添加/编辑弹窗-->
    </div>
</template>

<script>
    export default {
        name: "AdministratorManage",
        data() {
            return {
                adminTable: [],
                selectCollege: [],
                //管理员管理数据
                addAdminForm: {
                    name: '',
                    username: '',
                    college_id: '',
                    college_name: '',
                    head_portrait: '',
                    password: '',
                    image: '',
                    imageFile: '',
                },
                dialogAddAdmin: false,
                //弹窗标题
                adminTitleType: '',
                formLabelWidth: '80px',
                //确定/保存
                distinguishBtn: '',
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在1到20个字符', trigger: 'blur'},
                    ],
                    college_id: [
                        {required: true, message: '请选择学院', trigger: 'change'},
                    ],
                },
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.getAdminList();
            this.getCurrentList();
        },
        methods: {
            addAdministrator() {
                this.dialogAddAdmin = !this.dialogAddAdmin;
                this.adminTitleType = '创建学院管理员';
                this.distinguishBtn = 'add';
            },
            editAdmin(row) {
                this.dialogAddAdmin = !this.dialogAddAdmin;
                this.adminTitleType = '编辑学院管理员';
                this.distinguishBtn = 'edit';
                this.addAdminForm.id = row.id;
                this.addAdminForm.username = row.username;
                this.addAdminForm.name = row.name;
                this.addAdminForm.password = row.password;
                this.addAdminForm.college_id = row.college_id;
                this.addAdminForm.college_name = row.college_name;
                this.addAdminForm.image = row.head_portrait;
            },
            // 获取学院管理员列表
            getAdminList() {
                let param = {
                    page: this.adminPages.currentPageNum,
                    pageSize: this.adminPages.eachPageNum,
                    paging: 1,
                    type:1,
                };
                this.$http.axiosGetBy(this.$api.collegeAdmin, param, (res) => {
                    if (res.code === 200) {
                        this.adminTable = res.data.data;
                        this.adminPages.total = res.data.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取学院
            getCurrentList() {
                this.$http.axiosGet(this.$api.college, (res) => {
                    if (res.code === 200) {
                        this.selectCollege = res.data.data;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 学院选择
            classSelect(e) {
                this.addAdminForm.college_id = e;
            },
            //新增修改学院管理员
            addOrEdit(imageUrl){
                let formData = new FormData();
                formData.append("name", this.addAdminForm.name);
                formData.append("username", this.addAdminForm.username);
                formData.append("password", this.addAdminForm.password);
                formData.append("college_id", this.addAdminForm.college_id);
                formData.append("head_portrait", imageUrl);
                formData.append("type", 1);
                if (this.distinguishBtn === 'edit') {
                    formData.append("id", this.addAdminForm.id);
                }
                this.$http.axiosPost(this.$api.collegeAdmin, formData, res => {
                        if (res.code === 200) {
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                                onClose: () => {
                                    this.dialogAddAdmin = !this.dialogAddAdmin;
                                    this.resetForm();
                                    this.getAdminList();
                                }
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            },
            //添加/保存学生
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param = {
                           username:this.addAdminForm.username,
                           id:0
                        };
                        if (this.distinguishBtn === 'edit') {
                            param.id = this.addAdminForm.id;
                        }
                        this.$http.axiosGetBy(this.$api.username, param, res => {
                            if (res.code === 200) {
                                if (this.addAdminForm.imageFile) {
                                    let fileOb = new FormData();
                                    fileOb.append("file", this.addAdminForm.imageFile);
                                    this.$http.axiosPost(this.$api.upload, fileOb, res => {
                                        // console.log(res)
                                        if (res.code === 200) {
                                            this.addOrEdit(res.data)
                                        } else {
                                            this.$message({
                                                type: "error",
                                                message: res.message,
                                                duration: 1000
                                            });
                                        }
                                    })
                                } else {
                                    this.addOrEdit(this.addAdminForm.image)
                                }
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                    duration: 1000
                                });

                            }
                        })

                    }
                });
            },
            // 头像
            changeFile(e) {
                // console.log(123)
                if (this.beforeAvatarUpload(e.target.files[0])) {
                    this.addAdminForm.imageFile = e.target.files[0];
                    var fr = new FileReader();
                    fr.onloadend = (e) => {
                        this.addAdminForm.image = e.target.result;
                    };
                    fr.readAsDataURL(this.addAdminForm.imageFile);
                } else {
                    document.getElementById("id_avatar").value = "";
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error("上传头像图片只能是 JPG 格式!");
                }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isJPG && isLt2M;
            },
            //重置表单
            resetForm() {
                this.$refs.addAdminForm.resetFields();
                for (let key in this.addAdminForm) {
                    this.addAdminForm[key] = "";
                }
            },
            //重置密码
            resetPassword(row) {
                // console.log(row)
                this.$confirm("重置密码,默认123456", "提示消息", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true
                }).then(() => {
                    let param = {
                        id:row.id,
                        type:1
                    }
                    this.$http.axiosPost(this.$api.reset, param, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                }).catch(() => {
                    this.$message.info("已取消重置");
                });
            },
            // 删除学院
            deleteAdmin(row) {
                this.$confirm('是否删除该管理员，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.del, {id: row.id,type:1}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getAdminList(this.id);
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            //打开弹窗聚焦输入框
            openAddAdmin() {
                this.$refs.adminInput.focus();
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAdminList();
            },
        }
    }
</script>

<style scoped lang="scss">
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .up-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 32px;
        opacity: 0;
    }

    .up-img-show {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        vertical-align: middle;
        margin-left: 10px;
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 12px 9px;
    }

</style>